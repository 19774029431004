<template lang="pug">
include ../pug/svg
section#font
	h2 Title Text
	div.c2(v-if="h1Font")
		div.form
			div.field.req
				select(v-model="h1Font" @change="enableCheck('h1Weight')")
					option(v-for="f in fonts" :value="f.name") {{f.name}}
				label Font Name
				+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
			fieldset.fgroup
				div.field.c2.req
					select(v-model="h1Weight" @change="enableCheck()")
						option(v-for="weight in fontWeights(h1Font)" :value="weight") {{weight}}
					label Font Weight
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field.c2
					label Uppercase
					input(type="checkbox" v-model="h1Upper" :value="true" @change="enableCheck()")
					span.on(v-if="h1Upper") Yes
					span.off(v-else) No
			div.field.req
				textarea(type="text" rows="4" v-model="h1Text")
				label Preview Text
		div.preview
			h6 Preview
			h2(:style="{fontFamily:h1Font, fontWeight: h1Weight, textTransform: textTransform('h1Upper')}") {{h1Text}}
	h2 Heading Text
	div.c2(v-if="headingFont")
		div.form
			div.field.req
				select(v-model="headingFont" @change="enableCheck('headingWeight')")
					option(v-for="f in fonts" :value="f.name") {{f.name}}
				label Font Name
				+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
			fieldset.fgroup
				div.field.c2.req
					select(v-model="headingWeight" @change="enableCheck()")
						option(v-for="weight in fontWeights(headingFont)" :value="weight") {{weight}}
					label Font Weight
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field.c2
					label Uppercase
					input(type="checkbox" v-model="headingUpper" :value="true" @change="enableCheck()")
					span.on(v-if="headingUpper") Yes
					span.off(v-else) No
			div.field.req
				textarea(type="text" rows="4" v-model="headingText")
				label Preview Text
		div.preview
			h6 Preview
			h2(:style="{fontFamily:headingFont, fontWeight: headingWeight, textTransform: textTransform('headingUpper')}") {{headingText}}
	h2 Subheding Text
	div.c2(v-if="subheadingFont")
		div.form
			div.field.req
				select(v-model="subheadingFont" @change="enableCheck('subheadingWeight')")
					option(v-for="f in fonts" :value="f.name") {{f.name}}
				label Font Name
				+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
			fieldset.fgroup
				div.field.c2.req
					select(v-model="subheadingWeight" @change="enableCheck()")
						option(v-for="weight in fontWeights(subheadingFont)" :value="weight") {{weight}}
					label Font Weight
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field.c2
					label Uppercase
					input(type="checkbox" v-model="subheadingUpper" :value="true" @change="enableCheck()")
					span.on(v-if="subheadingUpper") Yes
					span.off(v-else) No
			div.field.req
				textarea(type="text" rows="4" v-model="subheadingText")
				label Preview Text
		div.preview
			h6 Preview
			h2(:style="{fontFamily:subheadingFont, fontWeight: subheadingWeight, textTransform: textTransform('subheadingUpper')}") {{subheadingText}}
	h2 Body Text
	div.c2(v-if="bodyFont")
		div.form
			div.field.req
				select(v-model="bodyFont" @change="enableCheck('bodyWeight')")
					option(v-for="f in fonts" :value="f.name") {{f.name}}
				label Font Name
				+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
			div.field.req
				select(v-model="bodyWeight" @change="enableCheck()")
					option(v-for="weight in fontWeights(bodyFont)" :value="weight") {{weight}}
				label Font Weight
				+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
			div.field.req
				textarea(type="text" rows="4" v-model="bodyText" @change="enableCheck()")
				label Preview Text
		div.preview
			h6 Preview
			p(:style="{fontFamily: bodyFont, fontWeight: bodyWeight}") {{bodyText}}
	h2 Label Text
	div.c2(v-if="labelFont")
		div.form
			div.field.req
				select(v-model="labelFont" @change="enableCheck('labelWeight')")
					option(v-for="f in fonts" :value="f.name") {{f.name}}
				label Font Name
				+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
			fieldset.fgroup
				div.field.c2.req
					select(v-model="labelWeight" @change="enableCheck()")
						option(v-for="weight in fontWeights(labelFont)" :value="weight") {{weight}}
					label Font Weight
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field.c2
					label Uppercase
					input(type="checkbox" v-model="labelUpper" :value="true" @change="enableCheck()")
					span.on(v-if="labelUpper") Yes
					span.off(v-else) No
			div.field.req
				textarea(type="text" rows="4" v-model="labelText")
				label Preview Text
		div.preview
			h6 Preview
			h2(:style="{fontFamily:labelFont, fontWeight: labelWeight, textTransform: textTransform('labelUpper')}") {{labelText}}

</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
	name: 'ManageThemeFont',
	props: ['cancelled'],
	emits: ['storeUpdated'],
	data() {
		return {
			h1Text: 'The quick brown fox jumps over the lazy dog.',
			headingText: 'The quick brown fox jumps over the lazy dog.',
			subheadingText: 'The quick brown fox jumps over the lazy dog.',
			labelText: 'The quick brown fox jumps over the lazy dog.',
			bodyText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In porttitor, libero sed tincidunt vehicula, mi quam faucibus purus nec pulvinar.',
			/*fonts: [
				{
					name: 'Roboto',
					weights: ['100','300','400','500','700','900'],
				},
				{
					name: 'Open Sans',
					weights: '300...800',
				},
				{
					name: 'Source Sans Pro',
					weights: ['200','300','400','600','700','900'],
				},
				{
					name: 'Lato',
					weights: ['100','300','400','700','900'],
				},
				{
					name: 'Merriweather',
					weights: ['300','400','700','900'],
				},
				{
					name: 'Montserrat',
					weights: ['100','200','300','400','500','600','700','800','900'],
				},
				{
					name: 'Lora',
					weights: ['400', '700'],
				},
				{
					name: 'Oswald',
					weights: ['200','300','400','500','600','700'],
				},
				{
					name: 'Playfair Display',
					weights: ['400','700','900'],
				},
				{
					name: 'Source Serif Pro',
					weights: ['400','600','700'],
				},
				{
					name: 'Bitter',
					weights: ['400','700'],
				},
				{
					name: 'Fira Mono',
					weights: ['400','500','700'],
				},
				{
					name: 'Pacifico',
					weights: ['400'],
				},
				{
					name: 'Libre Baskerville',
					weights: ['400', '700'],
				},
			],*/
		};
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: this.terminology('interface', 'manage', 'singular') + ' ' + this.terminology('theme','theme','singular'),
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},
		});
	},
	computed: {
		...mapFields('gui', { // get/set vuex store
			'fonts': 'fontOptions',
			'h1Font': 'cssFont.h1.name',
			'h1Weight': 'cssFont.h1.weight',
			'h1Upper': 'cssFont.h1.uppercase',
			'headingFont': 'cssFont.heading.name',
			'headingWeight': 'cssFont.heading.weight',
			'headingUpper': 'cssFont.heading.uppercase',
			'subheadingFont': 'cssFont.subheading.name',
			'subheadingWeight': 'cssFont.subheading.weight',
			'subheadingUpper': 'cssFont.subheading.uppercase',
			'bodyFont': 'cssFont.body.name',
			'bodyWeight': 'cssFont.body.weight',
			'bodyUpper': 'cssFont.body.uppercase',
			'labelFont': 'cssFont.label.name',
			'labelWeight': 'cssFont.label.weight',
			'labelUpper': 'cssFont.label.uppercase',
		}),
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		textTransform(fieldKey) {
			return this[fieldKey] ? 'uppercase' : 'none';
		},
		fontWeights(fontName) {
			const weights = this.fonts.find(f => f.name === fontName).weights;
			
			if (Array.isArray(weights)) {
				// non-variable font
				return weights;
				
			} else {
				// variable font
				const bounds = weights.split('..');
				const min = parseInt(bounds[0]);
				const max = parseInt(bounds[1]);
				const range = max - min;
				const step = 50;
				let increments = [];
				
				for (let i=0, l=Math.ceil(range/step); i<=l; i++) {
					const increment = min + Math.min(i*step, range);
					increments.push(increment.toString());
				}
				
				return increments;
			}
		},
		enableCheck(weightKey) {			
			if (weightKey) {
				// reset to default weight
				this[weightKey] = '400';
			}
			
			this.$emit('storeUpdated', {
				screen: 'font',
				valid: true,
			});			
		},
	},
}
</script>

<style lang="scss">
#font {
	>h2 {
		margin-top: 20px;
		font-size: 1.6rem;
		font-weight: 500;
	}
	.c2 {
		display: flex;
	}
	.form {
		width: 320px;
		min-width: 320px;
		margin-right: 20px;
		>:last-child {
			margin-bottom: 0;
		}
	}
	.preview {
		flex-grow: 1;
		border: 1px solid $lightgrey;
		border-radius: 10px;
		>:last-child {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			padding: 20px;
		}
		h6 {
			margin: -20px 10px 0 0;
			line-height: 20px;
			font-size: 1rem;
			font-weight: 500;
			text-transform: uppercase;
			text-align: right;
			color: $grey;
		}
	}
}
</style>
